import React from "react"

import Layout from "../components/templates/Layout"
import SEO from "../components/atoms/SEO"
import { Divider, Grid, Link, makeStyles, Paper, Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: theme.spacing(2),
      maxWidth: 350,
      width: '90%',
      height: 200
    },
    container: {
      height: '100%'
    },
    slim: {
      fontWeight: 300
    },
    divider: {
      marginTop: theme.spacing(1),
      width: new Date().getFullYear() - 1998,
      backgroundColor: theme.palette.common.black
    }
  }
})

const IndexPage = () => {
  const classes = useStyles()
  return (
    <Layout>
      <SEO
        title="Rahmat HD"
        keywords={["rahmathd", "engineer", "full-stack developer"]}
      />
      <Paper className={classes.root}>
        <Grid container direction="column" className={classes.container} justify="space-between">
          <Grid item>
            <Typography variant="h6" component="h1">
              Rahmat Hidayat
            </Typography>
            <Typography variant="body2" component="p" className={classes.slim} gutterBottom>
              I make websites
            </Typography>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item>
            <Typography variant="caption" component="p" align="right"className={classes.slim}>
              Email: <Link href="mailto:rahmatramahidayat@gmail.com">
                rahmatramahidayat@gmail.com
              </Link>
            </Typography>
            <Typography variant="caption" component="p" align="right"className={classes.slim}>
              Github: <Link href="https://github.com/rahmatrhd">
                @rahmatrhd
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Layout>
  )
}

export default IndexPage
